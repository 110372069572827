import React from "react"
import { navigate } from "gatsby"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import BulletList from "../../../../components/bullet-list"
import Bullet from "../../../../components/bullet"
import Paragraph from "../../../../components/paragraph"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"
import Heading from "../../../../components/heading"
import CourseSummary from "../../../../components/course-summary"
import CourseFooterBool from "../../../../components/course-footer-bool"
import { postVote } from "../../../../api"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const progress = getCourseProgress({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Hat dir der Kurs gefallen?"
          onSubmit={async (positive) => {
            try {
              await postVote({
                title: "versteckte-ordnung",
                upvoted: positive,
              })
            } catch (error) {}
            navigate("/kurse/versteckte-ordnung/feedback", {
              state: { positive },
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Eine kurze Zusammenfassung
          </Heading>
          <CourseSummary {...progress} />
          <Stack space={6}>
            <Paragraph>
              Es gibt zahlreiche Elemente, nach denen ein Gedicht geordnet sein
              kann:
            </Paragraph>
            <BulletList size={[3, 3, 4, 5]}>
              <Bullet>Nach der Anzahl der Verse in einer Strophe</Bullet>
              <Bullet>Nach Satz- und Wortwiederholungen.</Bullet>
              <Bullet>
                Nach wiederkehrenden Klängen, die eine Melodie erzeugen. Zum
                Beispiel durch die Wiederholung von Vokalen oder durch Reime.
              </Bullet>
              <Bullet>Nach ähnlichen Wort- und Versanfängen.</Bullet>
              <Bullet>
                Ein Gedicht kann auch wie ein Bild oder eine Figur angeordnet
                sein.
              </Bullet>
              <Bullet>
                Oder wie eine Liste aus lauter Aufzählungen bestehen.
              </Bullet>
            </BulletList>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
